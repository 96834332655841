// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const translations = {
    en: {
        translation: {
            "welcome": "Welcome to Ghana Tailor",
            "waistSize": "Waist size",
            "hipSize": "Hip size",
            "crotchSize": "Crotch size",
            "thighSize": "Thigh size",
            "length": "Length",
            "store": "Store",
            "cart": "Cart",
            "addCart": "Add to cart",
            "zipCode": "Zip code",
            "country": "Country",
            "firstName": "First name",
            "lastName": "Last name",
            "address": "Address",
            "email": "Email",
            "emptyCart": "Your cart is empty",
            "checkout" : "Checkout successfull",
            "buyMore": "BUY MORE",
            "totalItems": "Total Items",
            "totalPayment": "Total Payment",
            "clearCart" : "Clear cart",
            "price": "Price",
            "quantity": "Quantity",
            "tailoredCloth" : "Your tailored clothes",
            "pant": "pant",
            "pants": "Pants",
            "createPants": "Create your first pant",
            "tailors": "the tailors",
            "discoverTailors": "Discover the tailors",
            "explanation": "The approach",
            "explanationText": "Explaining Ghana tailor",
            "shipping": "Shipping",
            "autoSize": "Measures",
            "male": "Man",
            "female": "Woman",
            "phone": "Phone"
        }
    },
    fr: {
        translation: {
            "welcome": "Bienvenue",
            "waistSize": "Tour de taille",
            "hipSize": "Tour de hanche",
            "crotchSize": "Fourche avant",
            "thighSize": "Cuisse",
            "length": "Longueur ",
            "store": "Atelier",
            "cart": "Panier",
            "addCart": "Ajouter au panier",
            "zipCode": "Code postal",
            "country": "Pays",
            "firstName": "Prénom",
            "lastName": "Nom",
            "address": "Addresse",
            "email": "Email",
            "emptyCart": "votre panier est vide",
            "checkout" : "Succès du paiement ",
            "buyMore": "Acheter plus",
            "totalItems": "Total articles",
            "totalPayment": "Total à payer",
            "clearCart" : "Vider le panier",
            "price": "Prix",
            "quantity": "Quantité",
            "tailoredCloth" : "Vos vétements sur mesure",
            "pant": "pantalon",
            "pants": "Pantalons",
            "createPants": "Créez Votre premier pantalon",
            "tailors": "Les tailleurs",
            "discoverTailors": "Découvrez les tailleurs",
            "explanation": "La démarche",
            "explanationText": "On explique Ghana tailor",
            "shipping": "Livraison",
            "autoSize": "Mesures",
            "male": "Homme",
            "female": "Femme",
            "phone": "Téléphone"
        }
    }
};